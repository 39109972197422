import React, {useEffect, useState} from 'react';
import {IconCircle} from "../../../styles";
import {PercentageOutlined, TagOutlined} from "@ant-design/icons";
import BarChart from "../../../../../components/Charts/BarChart";
import {TpvHistory, DashboardsTotals} from "../../../types";

interface HistoricoTPVChartProps {
  data: DashboardsTotals | null;
}

const HistoricoTPVChart: React.FC<HistoricoTPVChartProps> = ({data}) => {
  const CHART_COLORS = {
    petrolGreen: 'rgb(14, 43, 39)',
  };

  const [chartDataHistoricoTPV, setChartData] = useState({
    labels: data?.tpvHistory ? data.tpvHistory.map((item: TpvHistory) => item.month) : [],
    datasets: [
      {
        label: "Valor total",
        data: data?.tpvHistory ? data.tpvHistory.map((item: TpvHistory) => item.monthTotal) : [],
        borderColor: "lightgray",
        borderWidth: 1,
        categoryPercentage: 0.5,
        backgroundColor: Object.values(CHART_COLORS),
      },
    ],
  });

  const [chartOptionsHistoricoTPV, setChartOptionsHistoricoTPV] = useState({
    scales: {
      x: {
        grid: {
          display: false
        }
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Distribuição do Valor Total de Vendas durante o ano",
      },
      legend: {
        position: 'bottom',
      },

      responsive: true,
      datalabels: {
        formatter: (value: number, context: any) => {
          const formatValue = (value: number) => {
            if (value >= 1_000_000_000) {
              return (value / 1_000_000_000).toFixed(2) + ' Bi';
            } else if (value >= 1_000_000) {
              return (value / 1_000_000).toFixed(2) + ' Mi';
            } else if (value >= 1000) {
              return (value / 1000).toFixed(2) + ' K';
            } else {
              return value.toFixed(2);
            }
          };
          const valorFormatado = formatValue(value);

          return `R$${valorFormatado}`;
        },
        color: 'Black',
        backgroundColor: 'white',
        position: 'bottom',
      },
    },

  });

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!data || !data.tpvHistory || data.tpvHistory.length === 0) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);

    const tempChartData = {...chartDataHistoricoTPV};
    tempChartData.labels = data.tpvHistory.map((item: TpvHistory) => item.month);
    tempChartData.datasets[0].data = data.tpvHistory.map((item: TpvHistory) => item.monthTotal);

    setChartData(tempChartData);
  }, [data]);

  return (
    <div>
      <div style={{display: 'flex'}}>
        <IconCircle style={{backgroundColor: 'rgba(170,255,244,0.5)'}}>
          <TagOutlined
            style={{
              fontSize: 18,
              color: 'rgb(0,255,222)'
            }}/>
        </IconCircle>
        <p style={{fontSize: '25px', marginTop: '0'}}>Histórico</p>
      </div>{isLoading ? (
      <p>Carregando dados...</p>
    ) : data && data.tpvHistory.length > 0 ? (
      <BarChart chartData={chartDataHistoricoTPV} chartOptions={chartOptionsHistoricoTPV} />
    ) : (
      <p>{'Sem resultados para essa pesquisa. \nTente alterar os dados do filtro.'}</p>
    )}
    </div>
  );
};

export default HistoricoTPVChart;
