import React, {useEffect, useState} from 'react';
import PieChart from "../../../../../components/Charts/PieChart";
import {IconCircle} from "../../../styles";
import {PercentageOutlined} from "@ant-design/icons";

// TROQUEI data por data só - ARTHUR BERNARDO
// criar um useEffect

const TipoPagamentoChart: React.FC<any> = ({data}) => {
  const [chartDataTipoPagamento, setChartData] = useState({
    labels: [
      "Débito",
      "Crédito à Vista",
      "2x-6x",
      "7x-12x",
      "13x-21x",
      "PIX",
    ],
    datasets: [
      {
        label: "Valor total",
        data: [
          data.totalDebit,
          data.totalCredit,
          data.totalCredit2x6,
          data.totalCredit7x12,
          data.totalCredit13x21,
          data.totalPix,
        ],
        borderColor: "lightgray",
        borderWidth: 1,
      },
    ],
  });
  const [chartOptionsTipoPagamento, setChartOptionsTipoPagamento] = useState({
    plugins: {
      title: {
        display: true,
        text: "Distribuição do Valor Total de Vendas por Tipo de Pagamento",
      },
      legend: {
        position: 'bottom',
      },
      responsive: true,
      datalabels: {
        formatter: (value: number, context: any) => {
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data as number[];
          dataArr.forEach(data => {
            sum += data;
          });
          const percentualValor = ((value * 100) / sum).toFixed(2);
          return (percentualValor.concat('%'));
        },
        color: 'white',
      },
    },
  });

  useEffect(() => {

      let formatColor: string[] = [
        "Débito",
        "Crédito à Vista",
        "2x-6x",
        "7x-12x",
        "13x-21x",
        "Pix",
      ]

      const backgroundcolor: string[] = [];

      for (let i = 0; i < formatColor.length; i++) {
        if (formatColor[i] === 'Débito') {
          backgroundcolor.push('rgb(14, 43, 39)');
        } else if (formatColor[i] === 'Crédito à Vista') {
          backgroundcolor.push('rgb(38, 119, 108)');
        } else if (formatColor[i] === '2x-6x') {
          backgroundcolor.push('rgb(62, 195, 177)'); // ou qualquer cor que você quiser associar aos ranges de parcelas
        } else if (formatColor[i] === '7x-12x') {
          backgroundcolor.push('rgb(13,169,153)');
        } else if (formatColor[i] === '13x-21x') {
          backgroundcolor.push('rgb(0,204,186)');
        } else if (formatColor[i] === 'PIX') {
          backgroundcolor.push('#e0a458');
        }
      }
      const tempChartData = {
        ...chartDataTipoPagamento,
        datasets: [
          {
            ...chartDataTipoPagamento.datasets[0],
            backgroundColor: backgroundcolor,
            data: [
              data.totalDebit,
              data.totalCredit,
              data.totalCredit2x6,
              data.totalCredit7x12,
              data.totalCredit13x21,
              data.totalPix,
            ],
          },
        ],
      };

      setChartData(tempChartData);
    }, [data]
  );

  return (
    <div style={{height: '554px !important'}}>
      <div style={{display: 'flex', paddingTop: '0.5em', height: '88px'}}>
        <IconCircle style={{backgroundColor: 'rgba(181,255,170,0.5)'}}>
          <PercentageOutlined
            style={{
              fontSize: 18,
              color: 'rgb(26,255,0)'
            }}/>
        </IconCircle>
        <p style={{fontSize: '25px', marginTop: '0', fontWeight: 'normal', marginLeft: '10px'}}>Vendas/Pagamento</p>
      </div>
      {data ? <PieChart chartData={chartDataTipoPagamento} chartOptions={chartOptionsTipoPagamento}/> :
        <p>{'Sem resultados para essa pesquisa. \nTente alterar os dados do filtro.'} </p>}
    </div>
  )
}

export default TipoPagamentoChart;
