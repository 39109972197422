import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {createFormatProvider} from '../../services/providers/factories';
import {updateContaDigital, updateSaldoCartao} from './actions';
import {InitialState, Reducer} from './actions/reducer';
import {ChartPanel, Container} from './styles';
import DashboardComercialServices from "./services/DashboardComercialServices";
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {DashboardsTotals, FilterData} from './types';
import Filter from './components/filter';
import TipoPagamentoChart from './components/charts/TipoPagamentoChart';
import Card from './components/card';
import LoadingOverlay from '../../components/LoadingOverlay';
import {formatDate} from "../../utils/formatDate";
import HistoricoTPVChart from "./components/charts/HistoricoTPVChart";
import Table from "./components/table";
import TerminaisServices from "../terminais/services/TerminaisServices";

const DashboardComercial: React.FC = () => {
  const dashboardComercialServices = new DashboardComercialServices();
  const terminaisServices = new TerminaisServices();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const [vendasData, setVendasData] = useState<any>([]);
  const [tpv, setTpv] = useState(0);
  const [averageTicket, setAverageTickedt] = useState(0);
  const [businessNumber, setBusinessNumber] = useState(0);
  const [recebiveisData, setRecebiveisData] = useState<any>([]);
  const [terminals, setTerminals] = useState();
  const [terminalsNumber, setTerminalsNumber] = useState(0);
  const [dashboardTotals, setDashboardTotals] = useState<DashboardsTotals>({
    averageTicket: 0,
    totalAmex: 0,
    totalCredit: 0,
    totalCredit2x6: 0,
    totalCredit7x12: 0,
    totalCredit13x21: 0,
    totalDebit: 0,
    totalElo: 0,
    totalGross: 0,
    totalLiquid: 0,
    totalMaster: 0,
    totalPix: 0,
    totalVisa: 0,
    totalHipercard: 0,
    tpvHistory: [],
  });

  let today: Date = new Date();
  let tomorrow: Date = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);

  const [filterData, setFilterData] = useState<FilterData>({
    InitDate: formatDate(today),
    FinishDate: formatDate(tomorrow),
    Status: 1,
  });

  Chart.register(CategoryScale);
  Chart.register(ChartDataLabels);

  const handleBusiness = async () => {
    const allBusiness = await dashboardComercialServices.getBusiness();
    setBusinessNumber(allBusiness.data.response.count);
  };

  const handleDashboardTotals = async () => {
    const result = await dashboardComercialServices.getDashboardTotals(filterData);
    setDashboardTotals(result.data.response);
  };

  const handleTerminals = async () => {
    const result = await terminaisServices.getAllTerminais(
      {
        id: 0,
        identifier: '',
        serialNumber: '',
        tid: '',
        status: ''
      }
    );
    setTerminals(result);
    setTerminalsNumber(result.data.response.count);
  }

  const handleSubmit = (data: FilterData): void => {
    setFilterData(data);
  };

  useEffect((): void => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await handleBusiness();
        await handleDashboardTotals();
        await handleTerminals();
      } catch (error) {
        console.log(error);
      }
      finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [filterData]);

  return (
    <Container>
      <LoadingOverlay show={isLoading}/>
      <Filter onSubmit={handleSubmit}/>
      <Card
        tpv={dashboardTotals.totalGross}
        businessNumber={businessNumber}
        averageTicket={averageTicket}
        terminalsNumber={terminalsNumber}
        cardData={dashboardTotals}
      />
      <div style={{
        display:'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '15px'
      }}>
        <ChartPanel>
          <HistoricoTPVChart data={dashboardTotals}/>
          <TipoPagamentoChart data={dashboardTotals}/>
        </ChartPanel>
        <Table tableData={dashboardTotals}/>
      </div>
    </Container>
  )
};

export default DashboardComercial;
